<template lang="html">
  <div className='page__404' style="text-align: center">
    <h1 style="font-size: 10em">404</h1>
    <p style="font-size: 5em">Page Not Found</p>
  </div>
</template>
<script>
export default {
  name: 'PageNotFound'
};
</script>
